import Mark from 'mark.js';

document.addEventListener('turbolinks:load', () => {
  const callShow = document.querySelector('.call-show');
  const searchInput = document.querySelector('#transcriptSearch');
  const searchButton = document.querySelector('#transcriptSearchButton');
  const keyWordDisplay = document.querySelector('#keyWord');
  const transcriptContainer = document.querySelector(
    '.call-interactive__transcript-container',
  );
  const searchQueryActions = document.querySelector(
    '.search__container-actions',
  );
  const matchCount = document.querySelector('#matchCounter');
  const markInstance = new Mark(transcriptContainer);

  const queryActions = {
    render: keyword => {
      searchQueryActions.style.display = 'block';

      if (
        searchInput.value === '' ||
        !transcriptContainer.innerText.includes(keyword)
      ) {
        keyWordDisplay.innerText = 'Keyword not found';
        queryActions.reset();

        return false;
      }
      keyWordDisplay.innerText = keyword;
    },
    reset: () => {
      markInstance.unmark();
      matchCounter.reset();
      traverseButtons.reset();
    },
  };

  const matchCounter = {
    render: (current, total, suffix = 'es') => {
      matchCount.innerText = `${current} of ${total} match${
        total !== 1 ? suffix : ''
      }`;
    },
    reset: () => {
      matchCount.innerText = '';
    },
  };

  const traverseButtons = {
    render: (nodeList, nodeSize = nodeList.length, currentIndex = 0) => {
      const previousButton = document.querySelector('#previousMatch');
      const nextButton = document.querySelector('#nextMatch');
      matchCounter.render(currentIndex + 1, nodeSize);
      nodeList[currentIndex].scrollIntoView();

      nextButton.addEventListener('click', () => {
        if (currentIndex === nodeSize - 1) {
          currentIndex = traverseButtons.scrollOver(0, nodeList);

          return;
        }

        nodeList[++currentIndex].scrollIntoView();
        matchCounter.render(currentIndex + 1, nodeSize);
      });

      previousButton.addEventListener('click', () => {
        if (currentIndex === 0) {
          currentIndex = traverseButtons.scrollOver(nodeSize - 1, nodeList);

          return;
        }

        nodeList[--currentIndex].scrollIntoView();
        matchCounter.render(currentIndex + 1, nodeSize);
      });
    },
    scrollOver: (currentIndex, nodeList) => {
      const total = nodeList.length;
      const current = currentIndex + 1;
      nodeList[currentIndex].scrollIntoView();
      matchCounter.render(current, total);

      return currentIndex;
    },
    reRenderButton: button => {
      const originalButton = document.querySelector(button);
      const clonedButton = originalButton.cloneNode(true);
      originalButton.parentNode.replaceChild(clonedButton, originalButton);
    },
    reset: () => {
      traverseButtons.reRenderButton('#previousMatch');
      traverseButtons.reRenderButton('#nextMatch');
    },
  };

  const highlightText = (keyword, nodeCollection = []) => {
    const options = {
      each: element => {
        nodeCollection.push(element);
      },
      accuracy: {
        value: 'partially',
        limiters: [',', '.'],
      },
      exclude: ['span', '.timecode *'],
    };

    markInstance.unmark({
      done: function() {
        markInstance.mark(keyword, options);
      },
    });

    traverseButtons.render(nodeCollection);
  };

  const initializeTranscriptSearch = () => {
    if (!callShow) {
      return;
    }

    searchButton.addEventListener('click', e => {
      e.preventDefault();

      const keyword = searchInput.value;
      if (queryActions.render(keyword) === false) {
        return;
      }

      highlightText(keyword);
    });
  };

  initializeTranscriptSearch();
});
