import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

document.addEventListener('turbolinks:load', () => {
  tippy.setDefaultProps({
    placement: 'top',
    trigger: 'focus',
    maxWidth: '250px',
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
      },
    },
  });

  tippy('#account_owner_input', {
    content:
      'R3M3MB3R.COM can be used by you as an individual or as a business. Please use the legal business name as the account owner if this is a business. ',
  });

  tippy('#user_phone_number_to_connect', {
    content:
      '<h3 class="t-center">We currently only service US phone numbers.</h3><p class="t-center fs-ninez">Contact us to know when we will service your country!</p>We will call you at this number when you place or receive phone calls to connect your phone call. You may use your mobile phone number or another phone number.',
  });

  tippy('#registration_password_field', {
    content: '12 Character Length Minimum',
  });

  tippy('#cloud-search-phone', {
    content:
      '<h4></h4>To make outgoing or receive incoming calls through r3m3mb3r.com requires a cloud-based phone number. This is the number that your customers will see in caller ID and will call back.',
  });

  tippy('#customer_billing_recharge_amount', {
    content:
      'How much do you want to charge to your credit card when your balance gets low? Be sure that it\'s enough to cover both inbound and outbout calling so that your customers do not get a "call cannot be completed" error. ',
  });
});
