document.addEventListener('turbolinks:load', () => {
  const callInformation = document.querySelector('.call-information');
  const callHead = document.querySelector('.call-header');
  const hideDetailsButton = document.querySelector('#hideDetails');
  const headers = document.querySelectorAll('h2');
  const backButton = document.querySelector('#backButton');
  const downloadButtons = document.querySelectorAll('.download-button');

  const toggleDisplay = () => {
    callInformation.classList.toggle('hide');
    callHead.classList.toggle('hide');
    headers.forEach(header => header.classList.toggle('hide'));
    downloadButtons.forEach(downloadBtn =>
      downloadBtn.classList.toggle('hide'),
    );
    backButton.classList.toggle('hide');
  };

  const initializeEventListeners = () => {
    if (document.querySelector('.call-information') === (undefined || null)) {
      return;
    }

    hideDetailsButton.addEventListener('click', e => {
      e.preventDefault();

      if (callInformation.classList.contains('hide')) {
        hideDetailsButton.innerText = 'Hide Details';
        toggleDisplay();

        return;
      }

      hideDetailsButton.innerText = 'Show Details';
      toggleDisplay();
    });
  };

  initializeEventListeners();
});
