import Headroom from 'headroom.js';

document.addEventListener('turbolinks:load', () => {
  const header = document.querySelector('.layout-main > header');

  if (header) {
    const headroom = new Headroom(header, {
      offset: 220,
      tolerance: {
        up: 0,
        down: 0,
      },
    });

    headroom.init();
  }
});
