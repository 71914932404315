document.addEventListener('turbolinks:load', () => {
  const selectGrid = document.querySelector('.cloud-select__form-grid');
  const radioButtons = document.querySelectorAll(
    '.cloud-select__form-grid-select',
  );
  const purchaseButton = document.querySelector('#purchaseSelectedButton');

  const initializeEventListeners = () => {
    if (selectGrid === (undefined || null)) {
      return;
    }

    let prev = null;
    for (let i = 0; i < radioButtons.length; i++) {
      radioButtons[i].addEventListener('change', function() {
        if (this !== prev) {
          prev = this;
        }
        purchaseButton.value = `Choose: ${this.firstElementChild.value}`;
        purchaseButton.style.display = 'block';
      });
    }
  };

  initializeEventListeners();
});
