document.addEventListener('turbolinks:load', () => {
  const useNameOnAccount = document.querySelector('#use_name_on_account');
  const cardHolderInput = document.querySelector('#cardholder-name');

  const initializeEventListeners = () => {
    if (useNameOnAccount === (undefined || null)) {
      return;
    }

    const accountUserName = document.querySelector('#default-name').value;

    useNameOnAccount.addEventListener('click', e => {
      if (e.target.checked === true) {
        cardHolderInput.value = accountUserName;
        return;
      }

      cardHolderInput.value = '';
    });
  };

  initializeEventListeners();
});
