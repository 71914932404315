document.addEventListener('turbolinks:load', function () {
	var div = document.getElementById('card-element');
	if (div === null) {
		return;
	}

	var stripe_public_key = document.querySelector('meta[name="stripe-public-key"]').getAttribute('content');
	var stripe = Stripe(stripe_public_key);

	var elements = stripe.elements();
	var cardElement = elements.create('card');
	cardElement.mount('#card-element');

	var cardholderName = document.getElementById('cardholder-name');
	var email = document.getElementById('cardholder-billing-email');
	var cardButton = document.getElementById('card-button');
	var clientSecret = cardButton.dataset.secret;

	cardButton.addEventListener('click', function (ev) {

		cardButton.disabled = true;

		stripe.confirmCardSetup(
			clientSecret, {
				payment_method: {
					card: cardElement,
					billing_details: {
						name: cardholderName.value,
						email: email.value
					}
				}
			}
		).then(function (result) {
			if (result.error) {
				// Display error.message in your UI.
				var displayError = document.getElementById("card-errors");
				displayError.textContent = result.error.message;

				cardButton.disabled = false;

			} else {
				// The setup has succeeded. Display a success message.
				payment_method_token = result.setupIntent.payment_method;
				token_field = document.getElementById('card_payment_method_token');
				token_field.value = payment_method_token;
				token_field.closest("form").submit();
			}
		});
	});
});
