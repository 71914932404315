document.addEventListener('turbolinks:load', () => {
  const multiFormContainer = document.querySelector('.multi-form');
  const saveNextButton = document.querySelector('#stageButton');
  const previousButton = document.querySelector('#prevButton');
  const multiForm = document.querySelector('form');
  const policyDiv = document.querySelector('#policyDetail');
  const stageLabels = document.querySelectorAll('.multi-form .stages label');

  const changeDOMButtons = index => {
    if (index === -1) return;

    if (index === 2) {
      saveNextButton.value = 'Save & Next';
    } else if (index === 1) {
      policyDiv.style.display = 'none';
      previousButton.style.width = '25%';
      previousButton.style.display = 'inline-block';
      saveNextButton.value = 'Next';
      saveNextButton.style.width = '70%';
      saveNextButton.style.marginLeft = '5%';
    } else {
      policyDiv.style.display = 'block';
      previousButton.style.display = 'none';
      saveNextButton.value = 'Next';
      saveNextButton.style.marginLeft = '0';
      saveNextButton.style.width = '100%';
    }
  };

  const initializeEventListeners = () => {
    if (multiFormContainer === (undefined || null)) {
      return;
    }

    saveNextButton.addEventListener('click', e => {
      e.preventDefault();

      const radio = document.querySelector(
        '.multi-form input[type="radio"]:checked',
      );
      const radios = Array.from(
        document.querySelectorAll('.multi-form input[type="radio"]'),
      );
      const currentRadio = radios.indexOf(radio);

      if (currentRadio === 2) {
        multiForm.submit();

        return;
      }

      const nextRadio = radios.indexOf(radio) + 1;

      radios[nextRadio].checked = true;
      changeDOMButtons(nextRadio);
    });

    previousButton.addEventListener('click', e => {
      e.preventDefault();

      const radio = document.querySelector(
        '.multi-form input[type="radio"]:checked',
      );
      const radios = Array.from(
        document.querySelectorAll('.multi-form input[type="radio"]'),
      );
      const prevRadio = radios.indexOf(radio) - 1;

      radios[prevRadio].checked = true;
      changeDOMButtons(prevRadio);
    });
  };

  initializeEventListeners();
});
